<template>
  <!--begin::Sender-->
  <div>
    <!--begin::Catchphrase-->
    <div
      class="block1 row"
      style="background-image: url('media/landing/transporter/fondo.jpg');"
    >
      <div class="col-lg-8 overlay-primary py-12 px-24">
        <p class="paragraph-white text-uppercase">
          {{ $t("LANDING.TRANSPORTER.BLOCK1.PRETITLE") }}
        </p>

        <div class="h1-white">
          {{ $t("LANDING.TRANSPORTER.BLOCK1.TITLE") }}
          <div class="tertiary-rectangle mt-3"></div>
        </div>

        <div class="paragraph-white mt-6 mb-18">
          {{ $t("LANDING.TRANSPORTER.BLOCK1.PARAGRAPH") }}
        </div>

        <div class="mb-18">
          <router-link to="/register/3">
            <button
              class="btn btn-pill button-form btn-tertiary text-uppercase px-12"
            >
              {{ $t("LANDING.TRANSPORTER.BLOCK1.BUTTON") }}
            </button>
          </router-link>
        </div>
      </div>
    </div>
    <!--end::Catchphrase-->

    <!--begin::Howto-->
    <div class="block4">
      <!--begin::Howto Navigator-->
      <div class="row">
        <div class="col-lg-12 text-center">
          <div class="h2-primary mb-9">
            {{ $t("LANDING.TRANSPORTER.BLOCK2.TITLE") }}
            <div class="tertiary-rectangle" style="margin: 0.75em auto;"></div>
          </div>
        </div>

        <div
          class="col-lg-3 block4-tag d-flex align-items-end justify-content-around"
          @click="$vuetify.goTo('.block4-step1', { offset: 50 })"
        >
          {{ $t("LANDING.TRANSPORTER.BLOCK2.STEP1.TAG") }}
        </div>
        <div
          class="col-lg-3 block4-tag d-flex align-items-end justify-content-around"
          @click="$vuetify.goTo('.block4-step2', { offset: 50 })"
        >
          {{ $t("LANDING.TRANSPORTER.BLOCK2.STEP2.TAG") }}
        </div>
        <div
          class="col-lg-3 block4-tag d-flex align-items-end justify-content-around"
          @click="$vuetify.goTo('.block4-step3', { offset: 50 })"
        >
          {{ $t("LANDING.TRANSPORTER.BLOCK2.STEP3.TAG") }}
        </div>
        <div class="col-lg-3 block4-tag">
          <router-link to="/register/3">
            <button
              class="btn btn-pill button-form btn-tertiary text-uppercase"
            >
              {{ $t("LANDING.TRANSPORTER.BLOCK2.BUTTON") }}
            </button>
          </router-link>
        </div>
      </div>
      <!--end::Howto Navigator-->

      <!--begin::Howto Step1-->
      <div class="row align-items-center block4-step1">
        <div class="col-lg-6 text-center py-0">
          <inline-svg
            src="/media/landing/transporter/group-1.svg"
            class="block4-step"
          />
        </div>
        <div class="col-lg-6">
          <div class="h3-primary">
            {{ $t("LANDING.TRANSPORTER.BLOCK2.STEP1.TITLE") }}
          </div>

          <div class="paragraph-primary mt-6">
            {{ $t("LANDING.TRANSPORTER.BLOCK2.STEP1.PARAGRAPH1") }}
          </div>
          <div class="paragraph-primary my-3 d-flex">
            <div class="tick-icon">
              <inline-svg src="/media/landing/index/validation-check_2.svg" />
            </div>
            <span>{{ $t("LANDING.TRANSPORTER.BLOCK2.STEP1.PARAGRAPH2") }}</span>
          </div>
          <div class="paragraph-primary my-3 d-flex">
            <div class="tick-icon">
              <inline-svg src="/media/landing/index/validation-check_2.svg" />
            </div>
            <span>{{ $t("LANDING.TRANSPORTER.BLOCK2.STEP1.PARAGRAPH3") }}</span>
          </div>
        </div>
      </div>
      <!--end::Howto Step1-->

      <!--begin::Howto Step2-->
      <div class="row align-items-center block4-step2">
        <div class="col-lg-6">
          <div class="h3-primary">
            {{ $t("LANDING.TRANSPORTER.BLOCK2.STEP2.TITLE") }}
          </div>

          <div class="paragraph-primary mt-6">
            {{ $t("LANDING.TRANSPORTER.BLOCK2.STEP2.PARAGRAPH1") }}
          </div>
          <div class="paragraph-primary my-3 d-flex">
            <div class="tick-icon">
              <inline-svg src="/media/landing/index/validation-check_2.svg" />
            </div>
            <span>{{ $t("LANDING.TRANSPORTER.BLOCK2.STEP2.PARAGRAPH2") }}</span>
          </div>
          <div class="paragraph-primary my-3 d-flex">
            <div class="tick-icon">
              <inline-svg src="/media/landing/index/validation-check_2.svg" />
            </div>
            <span>{{ $t("LANDING.TRANSPORTER.BLOCK2.STEP2.PARAGRAPH3") }}</span>
          </div>
          <div class="paragraph-primary my-3 d-flex">
            <div class="tick-icon">
              <inline-svg src="/media/landing/index/validation-check_2.svg" />
            </div>
            <span>{{ $t("LANDING.TRANSPORTER.BLOCK2.STEP2.PARAGRAPH4") }}</span>
          </div>
        </div>
        <div class="col-lg-6 text-center py-0">
          <inline-svg
            src="/media/landing/transporter/group-2.svg"
            class="block4-step"
          />
        </div>
      </div>
      <!--end::Howto Step2-->

      <!--begin::Howto Step3-->
      <div class="row align-items-center block4-step3">
        <div class="col-lg-6 text-center py-0">
          <inline-svg
            src="/media/landing/transporter/group-3.svg"
            class="block4-step"
          />
        </div>
        <div class="col-lg-6">
          <div class="h3-primary">
            {{ $t("LANDING.TRANSPORTER.BLOCK2.STEP3.TITLE") }}
          </div>

          <div class="paragraph-primary mt-6">
            {{ $t("LANDING.TRANSPORTER.BLOCK2.STEP3.PARAGRAPH1") }}
          </div>
          <div class="paragraph-primary my-3 d-flex">
            <div class="tick-icon">
              <inline-svg src="/media/landing/index/validation-check_2.svg" />
            </div>
            <span>{{ $t("LANDING.TRANSPORTER.BLOCK2.STEP3.PARAGRAPH2") }}</span>
          </div>
          <div class="paragraph-primary my-3 d-flex">
            <div class="tick-icon">
              <inline-svg src="/media/landing/index/validation-check_2.svg" />
            </div>
            <span>{{ $t("LANDING.TRANSPORTER.BLOCK2.STEP3.PARAGRAPH3") }}</span>
          </div>
          <div class="paragraph-primary my-3 d-flex">
            <div class="tick-icon">
              <inline-svg src="/media/landing/index/validation-check_2.svg" />
            </div>
            <span>{{ $t("LANDING.TRANSPORTER.BLOCK2.STEP3.PARAGRAPH4") }}</span>
          </div>
        </div>
      </div>
      <!--end::Howto Step3-->
    </div>
    <!--end::Howto-->

    <!--begin::Features-->
    <div class="block5a">
      <div class="h2-white">
        {{ $t("LANDING.TRANSPORTER.BLOCK3.TITLE") }}
        <div class="tertiary-rectangle" style="margin: 0.75em auto;"></div>
      </div>
      <div class="paragraph-white my-3">
        &nbsp;
      </div>
    </div>
    <div class="block7 row pt-3">
      <div class="col-lg-3">
        <inline-svg
          src="/media/landing/transporter/users.svg"
          class="block7-icon"
        />
        <div>
          {{ $t("LANDING.TRANSPORTER.BLOCK3.FEATURE1") }}
        </div>
      </div>
      <div class="col-lg-3">
        <inline-svg
          src="/media/landing/transporter/calculo-automatico.svg"
          class="block7-icon"
        />
        <div>
          {{ $t("LANDING.TRANSPORTER.BLOCK3.FEATURE2") }}
        </div>
      </div>
      <div class="col-lg-3">
        <inline-svg
          src="/media/landing/transporter/tarifas-clientes.svg"
          class="block7-icon"
        />
        <div>
          {{ $t("LANDING.TRANSPORTER.BLOCK3.FEATURE3") }}
        </div>
      </div>
      <div class="col-lg-3">
        <inline-svg
          src="/media/landing/transporter/cercanias.svg"
          class="block7-icon"
        />
        <div>
          {{ $t("LANDING.TRANSPORTER.BLOCK3.FEATURE4") }}
        </div>
      </div>
      <div class="col-lg-3">
        <inline-svg
          src="/media/landing/transporter/descuento-temporal.svg"
          class="block7-icon"
        />
        <div>
          {{ $t("LANDING.TRANSPORTER.BLOCK3.FEATURE5") }}
        </div>
      </div>
      <div class="col-lg-3">
        <inline-svg
          src="/media/landing/transporter/fechas-entrega-recogida.svg"
          class="block7-icon"
        />
        <div>
          {{ $t("LANDING.TRANSPORTER.BLOCK3.FEATURE6") }}
        </div>
      </div>
      <div class="col-lg-3">
        <inline-svg
          src="/media/landing/transporter/chat.svg"
          class="block7-icon"
        />
        <div>
          {{ $t("LANDING.TRANSPORTER.BLOCK3.FEATURE7") }}
        </div>
      </div>
      <div class="col-lg-3">
        <inline-svg
          src="/media/landing/transporter/conductor.svg"
          class="block7-icon"
        />
        <div>
          {{ $t("LANDING.TRANSPORTER.BLOCK3.FEATURE8") }}
        </div>
      </div>
      <div class="col-lg-3">
        <inline-svg
          src="/media/landing/transporter/seguimiento.svg"
          class="block7-icon"
        />
        <div>
          {{ $t("LANDING.TRANSPORTER.BLOCK3.FEATURE9") }}
        </div>
      </div>
      <div class="col-lg-3">
        <inline-svg
          src="/media/landing/transporter/app-conductores.svg"
          class="block7-icon"
        />
        <div>
          {{ $t("LANDING.TRANSPORTER.BLOCK3.FEATURE10") }}
        </div>
      </div>
      <div class="col-lg-3">
        <inline-svg
          src="/media/landing/transporter/incidencias.svg"
          class="block7-icon"
        />
        <div>
          {{ $t("LANDING.TRANSPORTER.BLOCK3.FEATURE11") }}
        </div>
      </div>
      <div class="col-lg-3">
        <inline-svg
          src="/media/landing/transporter/ratings.svg"
          class="block7-icon"
        />
        <div>
          {{ $t("LANDING.TRANSPORTER.BLOCK3.FEATURE12") }}
        </div>
      </div>

      <div class="col-lg-12 pt-18 pb-9">
        <router-link to="/register/3">
          <button
            class="btn btn-pill button-form btn-tertiary text-uppercase px-12"
          >
            {{ $t("LANDING.TRANSPORTER.BLOCK4.BUTTON") }}
          </button>
        </router-link>
      </div>
    </div>
    <!--end::Features-->
  </div>
  <!--end::Sender-->
</template>

<script>
export default {
  name: "LandingTransporter",

  mounted() {
    this.$nextTick(() => {
      document.title = this.$t("LANDING.TRANSPORTER.BLOCK1.PRETITLE");
    });
  }
};
</script>
